import React from "react";
import "./Companies.css";
const Companies = () => {
  return (
    <div>
      <section className="c-wrapper">
        <div className="c-container paddings flexCenter innerWidth ">
          <img src="../images/prologis.png" alt="c1" />
          <img src="../images/tower.png" alt="c2" />
          <img src="./images/equinix.png" alt="c3" />
          <img src="./images/realty.png" alt="c4" />
        </div>
      </section>
    </div>
  );
};

export default Companies;
